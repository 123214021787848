class ResetSenhaModel {
  email: string;
  novaSenha: string;

  // Construtor com valores padrão
  constructor(email: string = "", novaSenha: string = "") {
    this.email = email;
    this.novaSenha = novaSenha;
  }

  // Método para transformar o modelo em um objeto JSON
  toJson(): Record<string, string> {
    return {
      email: this.email,
      novaSenha: this.novaSenha,
    };
  }

  // Método estático para criar a instância a partir de um JSON
  static fromJson(json: Partial<ResetSenhaModel>): ResetSenhaModel {
    return new ResetSenhaModel(
      json.email || "",
      json.novaSenha || ""
    );
  }
}

export default ResetSenhaModel;
