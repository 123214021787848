import axios from "axios";

// Função para obter o valor de um cookie específico
export const GetTokenCookie = (name: string): string | null => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) return parts.pop()?.split(";").shift() || null;
  return null;
};

export const api = axios.create({
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

// Adicionar um interceptor para incluir o token
api.interceptors.request.use(
  (config) => {
    const token = GetTokenCookie("authToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const apiDBWEB = axios.create({
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

// Adicionar um interceptor para incluir o token
apiDBWEB.interceptors.request.use(
  (config) => {
    const token = GetTokenCookie("TokenDBWEB");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;
