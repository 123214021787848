import api from "./Auth/Inteceptor";
import environment from "../environments/environments";
import SingupModel from "../models/signup.model";
import ResetSenhaModel from "../models/resetsenha.model";
import CadCadastroModel from "../models/cadcadastro.model";
import caddocumento from "../models/caddocumento.model";
import CadastroEnderecoModel from "../models/cadendereco.model";
import { getCookie } from "./Utils.Service";

const urlRegistroUser = environment.urlRegistroUser;
const urlConfirmaEmail = environment.urlConfirmaEmail;
const urlReenviaEmail = environment.urlReenviaEmail;
const urlResetSenha = environment.urlResetSenha;
const urlTrocarSenha = environment.urlTrocarSenha;
const urlCadastro = environment.urlCadastro;
const urlCadDocumento = environment.urlCadDocumento;
const urlCadEndereco = environment.urlCadEndereco;
const urlAssociar = environment.urlAssociar;

export const PostSignUp = async (formData: SingupModel) => {
  try {
    await api.post(`${urlRegistroUser}`, formData);
    return { success: true };
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.info?.dados?.descricao || "Erro desconhecido";
    return { success: false, error: errorMessage };
  }
};

export const ReenvioToken = async (email: string) => {
  try {
    await api.get(`${urlReenviaEmail}${email}`);
    return { success: true };
  } catch (error: any) {
    return { success: false, error: true };
  }
};

export const ConfirmarEmail = async (token: string | undefined) => {
  try {
    await api.post(`${urlConfirmaEmail}${token}`, "");
    return { success: true };
  } catch (error: any) {
    return { success: false, error: true };
  }
};

export const ResetSenha = async (email: string) => {
  try {
    await api.get(`${urlResetSenha}${email}`);
    return { success: true };
  } catch (error: any) {
    return { success: false, error: true };
  }
};

export const TrocaSenha = async (token: string | undefined, body: ResetSenhaModel) => {
  try {
    await api.post(`${urlTrocarSenha}${token}`, body);
    return { success: true };
  } catch (error: any) {
    return { success: false, error: true };
  }
};

export const getCadastro = async (cnpj: string) => {
  try {
    const response = await api.get(`${urlCadastro}?cpf_cnpj=${cnpj}`);
    return response;
  } catch (error: any) {
    return (error.response.data.info?.dados?.descricao || error?.message)  
  }
};

export const postCadastro = async (contato: CadCadastroModel) => {
  try {
    const response = await api.post(`${urlCadastro}`, contato);
    return response;
  } catch (error: any) {
    console.log(error)
    return (error.response.data.info?.dados?.descricao || error?.message)  
  }
};

export const putAssociar = async (idpj:string) => {
  const json = {
    id_cad_pj: idpj,
    id_cad_pf: getCookie("idCad"),
    associar: "Incluir",
  };
  try {
    const response = await api.put(`${urlAssociar}`, json);
    return response.status;  
  } catch (error: any) {
      console.error("Erro ao mandar Modulo:", error.response.data.info?.dados?.descricao || error);
    ;
  }
};

export const postDocumento = async (documento: caddocumento) => {
  try {
    const response = await api.post(`${urlCadDocumento}`, documento);
    return response;
  } catch (error: any) {
    return (error.response.data.info?.dados?.descricao || error?.message)  
  }
};

export const postEndereco = async (end: CadastroEnderecoModel) => {
  try {
    const response = await api.post(`${urlCadEndereco}`, end);
    return response;
  } catch (error: any) {
    return (error.response.data.info?.dados?.descricao || error?.message)  
  }
};
