interface Logradouro {
  cod_logradouro: number;
  cep: string;
  logradouro: string;
  bairro: string;
  cidade: string;
  uf: string;
  cod_ibge: number;
  ddd: string | null;
  cod_pais_sped: number;
  pais: string;
}

export default class CadastroEnderecoModel {
  id_cadastro: string;
  cod_cad_endereco: number;
  tipo_endereco: string;
  cod_logradouro: number;
  logradouro: Logradouro;
  numero_endereco: string;
  numero_complemento: string;

  constructor(
    id_cadastro: string = "",
    cod_cad_endereco: number = 0,
    tipo_endereco: string = "Principal",
    cod_logradouro: number = 0,
    logradouro: Logradouro = {
      cod_logradouro: 0,
      cep: "",
      logradouro: "",
      bairro: "",
      cidade: "",
      uf: "",
      cod_ibge: 0,
      ddd: null,
      cod_pais_sped: 1058,
      pais: "Brasil",
    },
    numero_endereco: string = "",
    numero_complemento: string = ""
  ) {
    this.id_cadastro = id_cadastro;
    this.cod_cad_endereco = cod_cad_endereco;
    this.tipo_endereco = tipo_endereco;
    this.cod_logradouro = cod_logradouro;
    this.logradouro = logradouro;
    this.numero_endereco = numero_endereco;
    this.numero_complemento = numero_complemento;
  }
}
