import React, { useState, useEffect } from "react";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import { TextField, IconButton, Button, InputAdornment } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import ResetSenhaModel from "../../models/resetsenha.model";
import {TrocaSenha} from "../../services/Login.Service"
import * as CryptoJS from "crypto-js";
import "./Reset.scss";

const Reset = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [formreset, setFormreset] = useState<ResetSenhaModel>(new ResetSenhaModel("", ""));
  const [novaSenha, setNovaSenha] = useState<string>("");
  const [confirmaSenha, setConfirmaSenha] = useState<string>("");
  const [senhaValida, setSenhaValida] = useState<boolean>(true);
  const [senhaConfirmaValida, setSenhaConfirmaValida] = useState<boolean>(true);
  const [status, setStatus] = useState<number>(0); // 0 - campo digitável, 1 - Token Válido, 2 - Token Inválido

  const handleNovaSenhaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNovaSenha(e.target.value);
    const isSenhaValida =
      e.target.value.length >= 8 &&
      /[a-zA-Z]/.test(e.target.value) &&
      /[0-9]/.test(e.target.value);
    setSenhaValida(isSenhaValida);
  };

  useEffect(() => {
    if (novaSenha) {
      const hashpassword = CryptoJS.SHA256(novaSenha).toString();
      // Garantir que estamos criando uma instância de ResetSenhaModel
      setFormreset((prevForm) => new ResetSenhaModel(prevForm.email, hashpassword));
    }
  }, [novaSenha]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Criando uma nova instância de ResetSenhaModel
    setFormreset((prevForm) => new ResetSenhaModel(e.target.value, prevForm.novaSenha));
  };

  const handleConfirmaSenhaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmaSenha(e.target.value);
    setSenhaConfirmaValida(novaSenha === e.target.value);
  };

  const MudarSenha = async (e: React.FormEvent) => {
    e.preventDefault();

    // Verificar se as senhas são válidas antes de enviar
    if (!senhaValida || !senhaConfirmaValida) {
      return;
    }
    const result = await TrocaSenha(token, formreset);
    if (result.success) {
      setStatus(1); // Token válido
    } else { 
      setStatus(2); // Token inválido     
    }
  };

  return (
    <div className="card-reset">
      <h2>Redefinição de Senha</h2>

      {status === 0 && (
        <form className="form" onSubmit={MudarSenha}>
          <div className="input-group">
            <TextField
              id="email"
              type="text"
              label="Confirme seu Email"
              value={formreset.email}
              onChange={handleEmailChange}
              variant="outlined"
              fullWidth
            />
          </div>

          <div className="input-group">
            <TextField
              id="novaSenha"
              type="password"
              label="Digite sua nova Senha"
              value={novaSenha}
              onChange={handleNovaSenhaChange}
              variant="outlined"
              fullWidth
              error={!senhaValida}
              helperText={
                !senhaValida
                  ? "A senha deve conter pelo menos 8 dígitos, letras e números."
                  : ""
              }
            />
          </div>

          <div className="input-group">
            <TextField
              id="confirmaSenha"
              type="password"
              label="Confirme sua senha"
              value={confirmaSenha}
              onChange={handleConfirmaSenhaChange}
              variant="outlined"
              fullWidth
              error={!senhaConfirmaValida}
              helperText={ !senhaConfirmaValida ? "As senhas não coincidem." : ""}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      disabled={!senhaConfirmaValida || formreset.email === ''}
                      type="submit"
                      className="submit-icon"
                    >
                      <ArrowCircleRightOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </form>
      )}

      {status === 1 && (
        <div>
          <h2>Senha Alterada com sucesso!</h2>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/login")}
          >
            Fazer Login
          </Button>
        </div>
      )}

      {status === 2 && (
        <div>
          <h2>Token Expirado! Solicite a redefinição novamente.</h2>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/login")}
          >
            Voltar
          </Button>
        </div>
      )}
    </div>
  );
};

export default Reset;
