interface TipoDocumento {
  cod_tipo_documento: number;
  ds_tipo_documento: string;
  tipo_pessoa: string; 
}

export default class caddocumento {
  id_cadastro: string;
  cod_documento: number;
  tipo_documento: TipoDocumento;
  ds_documento: string;

  constructor(
    id_cadastro: string = "",
    cod_documento: number = 0,
    tipo_documento: TipoDocumento = {
      cod_tipo_documento: 0,
      ds_tipo_documento: "",
      tipo_pessoa: "Fisica",
    },
    ds_documento: string = ""
  ) {
    this.id_cadastro = id_cadastro;
    this.cod_documento = cod_documento;
    this.tipo_documento = tipo_documento;
    this.ds_documento = ds_documento;
  }
}
