import { useEffect, useRef } from "react";
import { logout } from "./Auth/Auth.Service";
import { useNavigate } from "react-router-dom";

const AutoLogout: React.FC = () => {
  const navigate = useNavigate();
  const timeoutRef = useRef<number | null>(null); // Tipagem do timeoutRef

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(() => {
      logout();
      navigate("/login");
    }, 3600000); // 1 hora
  };

  useEffect(() => {
    const events = [
      "mousemove",
      "mousedown",
      "keypress",
      "scroll",
      "touchstart",
    ];

    // Adiciona eventos
    events.forEach((event) => {
      window.addEventListener(event, resetTimeout);
    });

    // Inicia o timeout inicial
    resetTimeout();

    return () => {
      // Remove eventos
      events.forEach((event) => {
        window.removeEventListener(event, resetTimeout);
      });

      // Limpa o timeout ao desmontar o componente
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [navigate]);

  return null;
};

export default AutoLogout;
