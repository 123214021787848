import React, { useState } from "react";
import { FormControl, TextField, Button, Box, Stepper, Step, StepLabel } from "@mui/material";
import FormHelperText from "@mui/material/FormHelperText";
import * as CryptoJS from 'crypto-js';
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from 'dayjs';
import "dayjs/locale/pt-br";
import "./Signup.scss";
import { styled } from "@mui/material/styles";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import ArticleIcon from "@mui/icons-material/Article";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { StepIconProps } from '@mui/material/StepIcon';
import { useNavigate } from "react-router-dom";
import { validateCPF } from "../../components/validaCPF";
import { Adulto } from "../../components/verificaIdade";
import SingupModel from "../../models/signup.model";
import {PostSignUp } from "../../services/Login.Service"

const steps: string[] = [
  "Criação de Conta",
  "Contrato e Aceitação",
  "Validação por E-mail",
];

const Signup: React.FC = () => {
  const navigate = useNavigate();

  const Voltar = () => {
    navigate("/Login");
  };

  const [activeStep, setActiveStep] = useState<number>(0);
  const [formData, setFormData] = useState<SingupModel>(new SingupModel());
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [fim, setFim] = useState<string>("");
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [isOfAge, setIsOfAge] = useState<boolean | null>(null);
  const [isValidPassword, setIsValidPassword] = useState<string | boolean>("");
  const [isPasswordOk, setIsPasswordOk] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean | null>(null);

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setFormData((prevData) => new SingupModel(
      prevData.nome, prevData.cpf, prevData.dataNascimento, prevData.email, value
    ));

    const hasLetter = /[a-zA-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    const isValidLength = value.length >= 8;

    if (hasLetter && hasNumber && isValidLength) {
      setIsValidPassword(true);
    } else {
      setIsValidPassword(
        "A senha deve ter pelo menos 8 caracteres, contendo letras e números."
      );
    }
  };

  const handleChangePasswordConfirm = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setConfirmPassword(value);
    
    setIsPasswordOk(formData.senha === value);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    
    setFormData((prevUser) => new SingupModel(
      name === "nome" ? value : prevUser.nome,
      name === "cpf" ? value : prevUser.cpf,                 
      name === "dataNascimento" ? value : prevUser.dataNascimento,  
      name === "email" ? value : prevUser.email,
      name === "senha" ? value : prevUser.senha,
    ));    
  };

  const handleDateChange = (date: Dayjs | null) => {    
    if (date && date.isValid()) { 
      const dtnasc = date.toISOString();  
      const adult = Adulto(dtnasc); 
      
      setIsOfAge(adult);
      
      setFormData((prevData) => 
        new SingupModel(
          prevData.nome,
          prevData.cpf,
          dtnasc,
          prevData.email,
          prevData.senha
        )
      );
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(e.target.checked);
  };

  const handleChangeCPF = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const valid = validateCPF(value);
    setIsValid(valid);

    setFormData((prevData) => new SingupModel(
      prevData.nome, value, prevData.dataNascimento, prevData.email, prevData.senha
    ));
  };

  const handleNext = () => {
    if (activeStep === 0) {
      if (
        !formData.nome ||
        !formData.cpf ||
        !formData.dataNascimento ||
        !formData.email ||
        !formData.senha
      ) {
        alert("Preencha todos os campos obrigatórios.");
        return;
      }
    }

    if (activeStep === 1) {  
      setFim("");   
      if (termsAccepted) {
        const hashPassword = CryptoJS.SHA256(formData.senha).toString();
        formData.senha = hashPassword;
        postform();
  };
    }

    setActiveStep((prevStep) => prevStep + 1);
  };

  const postform = async () =>{
    const result = await PostSignUp(formData);
    if (result.success) {
      setFim("Por favor, verifique seu e-mail para completar o seu registro.")
    }
    else {
      setFim("Erro" + result.error)  
    }   
  }

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!termsAccepted) {
      return;
    }
  };

  // Styled components with TypeScript
  const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
      color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    "& .QontoStepIcon-completedIcon": {
      color: "#784af4",
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
    ...theme.applyStyles("dark", {
      color: theme.palette.grey[700],
    }),
    variants: [
      {
        props: ({ ownerState }) => ownerState.active,
        style: {
          color: "#784af4",
        },
      },
    ],
  }));

  function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;
  
    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Check className="QontoStepIcon-completedIcon" />
        ) : (
          <div className="QontoStepIcon-circle" />
        )}
      </QontoStepIconRoot>
    );
  }

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(72,187,248) 0%,rgb(29,90,132) 50%,rgb(76,28,132) 100%)",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          "linear-gradient( 95deg,rgb(72,187,248) 0%,rgb(29,90,132) 50%,rgb(76,28,132) 100%)",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: "#eaeaf0",
      borderRadius: 1,
      ...theme.applyStyles("dark", {
        backgroundColor: theme.palette.grey[800],
      }),
    },
  }));

  const ColorlibStepIconRoot = styled('div')<{ ownerState: { completed?: boolean, active?: boolean } }>(
    ({ theme, ownerState }) => ({
      backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[700],
    }),
    variants: [
      {
        props: ({ ownerState }) => ownerState.active,
        style: {
          backgroundImage:
            "linear-gradient( 136deg, rgb(72,187,248) 0%, rgb(29,90,132) 50%, rgb(76,28,132) 100%)",
          boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
        },
      },
      {
        props: ({ ownerState }) => ownerState.completed,
        style: {
          backgroundImage:
            "linear-gradient( 136deg, rgb(72,187,248) 0%, rgb(29,90,132) 50%, rgb(76,28,132) 100%)",
        },
      },
    ],
  }));

  function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement<unknown> } = {
      1: <SettingsIcon />,
      2: <ArticleIcon />,
      3: <MarkEmailReadIcon />,
    };

    return (
      <ColorlibStepIconRoot
        ownerState={{ completed, active }}
        className={className}
      >
        {icons[String(props.icon)]}
      </ColorlibStepIconRoot>
    );
  }

  return (
    <>
      <div className="conteiner">
        <Box className="signup-container">
          <Box className="card-signup">
            <h2 className="title">Criação de Conta</h2>

            <Stepper
              activeStep={activeStep}
              alternativeLabel
              connector={<ColorlibConnector />}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>

            <form onSubmit={handleSubmit}>
              {activeStep === 0 && (
                <Box>
                  <FormControl
                    fullWidth
                    margin="normal"
                    className="form-control"
                  >
                    <TextField
                      label="Nome"
                      name="nome"
                      value={formData.nome}
                      onChange={handleChange}
                      required
                      className="input-field name-input"
                    />
                  </FormControl>

                  <FormControl
                    fullWidth
                    margin="normal"
                    className="form-control"
                  >
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="pt-br"
                    >
                      <DatePicker
                        label="Data de Nascimento"                        
                        value={dayjs(formData.dataNascimento)}
                        onChange={(newdate)=> handleDateChange(newdate)}                                                                    
                      />
                      {isOfAge === false && (
                        <p style={{ color: "red" }}>
                          Cadastro não autorizado por ser menor de idade.
                        </p>
                      )}
                    </LocalizationProvider>
                  </FormControl>

                  <FormControl
                    fullWidth
                    margin="normal"
                    className="form-control"
                  >
                    <TextField
                      label="CPF"
                      name="cpf"
                      value={formData.cpf}
                      onChange={handleChangeCPF}
                      placeholder="123.456.789-00"
                      required
                      className="input-field cpf-input"
                      inputProps={{ maxLength: 11 }}
                    />
                    {isValid === false && (
                      <p style={{ color: "red" }}>CPF inválido</p>
                    )}
                    <FormHelperText>** Somente Números</FormHelperText>
                  </FormControl>

                  <FormControl
                    fullWidth
                    margin="normal"
                    className="form-control"
                  >
                    <TextField
                      label="E-mail"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      type="email"
                      required
                      className="input-field email-input"
                    />
                  </FormControl>

                  <FormControl
                    fullWidth
                    margin="normal"
                    className="form-control"
                  >
                    <TextField
                      label="Senha"
                      name="password"
                      value={formData.senha}
                      onChange={handleChangePassword}
                      type="password"
                      required
                      className="input-field password-input"
                      error={typeof isValidPassword === 'string'}
                    />
                     {typeof isValidPassword === 'string' && <FormHelperText>{isValidPassword}</FormHelperText>}
                  </FormControl>

                  <FormControl
                    fullWidth
                    margin="normal"
                    className="form-control"
                  >
                    <TextField
                      label="Confirmar Senha"
                      name="confirmPassword"
                      onChange={handleChangePasswordConfirm} 
                      type="password"
                      value={confirmPassword}
                      required
                      className="input-field confirm-password-input"                      
                    />
                    {!isPasswordOk && <FormHelperText>As Senhas não coincidem</FormHelperText>}
                  </FormControl>

                  <Button
                    disabled={isValid === false || isOfAge === false}
                    onClick={handleNext}
                    variant="contained"
                    color="primary"
                  >
                    Próximo
                  </Button>
                </Box>
              )}

              {activeStep === 1 && (
                <Box>
                  <h3 className="subtitle">
                    Leia o contrato e aceite para continuar
                  </h3>
                  <Box className="contract-container">
                    <div>
                      <TextField
                        multiline
                        rows={10}
                        value=""
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    </div>
                  </Box>

                  <FormControl
                    fullWidth
                    margin="normal"
                    className="form-control"
                  >
                    <div className="terms-container">
                      <input
                        type="checkbox"
                        id="terms"
                        name="termsAccepted"
                        checked={termsAccepted}
                        onChange={handleCheckboxChange}
                        style={{marginRight:"8px"}}
                      />
                      <label htmlFor="terms" className="terms-label">
                        Li e aceito os termos de uso e o contrato.
                      </label>
                    </div>                    
                  </FormControl>

                  <Button
                    onClick={handleBack}
                    variant="outlined"
                    color="secondary"
                    sx={{ mr: 1 }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={!termsAccepted}
                    onClick={handleNext}
                    variant="contained"
                    color="primary"
                  >
                    Próximo
                  </Button>
                </Box>
              )}

              {activeStep === 2 && (
                <Box>
                  <h3 className="subtitle">
                  {fim}
                  </h3>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={Voltar}
                  >
                    ok
                  </Button>
                </Box>
              )}
            </form>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Signup;