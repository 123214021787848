import dayjs from 'dayjs';

export function calculateAge(birthDate:any) {
  const today = dayjs();
  const birth = dayjs(birthDate);
  let age = today.year() - birth.year();
  const monthDifference = today.month() - birth.month();

  if (monthDifference < 0 || (monthDifference === 0 && today.date() < birth.date())) {
    age--;
  }

  return age;
}

export function Adulto(birthDate:string) {
  return calculateAge(birthDate) >= 18;
}