import { GetTokenDBWEB, isAuthenticatedDBWEB } from "./Auth/Auth.Service";
import { api, apiDBWEB } from "./Auth/Inteceptor";
import environment from "../environments/environments";

const urlBuscaCnpjTanis = environment.urlBuscaCnpjTanis;
const urlBuscaCnpj = environment.urlBuscaCnpj;
const urlCadstro = environment.urlCadastro;
const urlBuscaCep = environment.urlBuscaCep;

export const getCookie = (name: string): string => {  
  const cookies = document.cookie.split('; ');

  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split('=');
    if (decodeURIComponent(cookieName.trim()) === name) {
      return decodeURIComponent(cookieValue.trim());
    }
  }
  
  return ""; 
};

export const getCadastroAssociacoes = async (id: string) => {
  try {
    const response = await api.get(`${urlCadstro}/${id}`);
    return response.data.info.dados.associacoes;
  } catch (error: any) {
    console.error("Erro ao buscar Cadastro:", error?.message || error);
  }
};

export const getBuscaCep = async (cep: string) => {
  try {
    const response = await api.get(`${urlBuscaCep}/${cep}`);
    return response.data.info.dados.cod_logradouro;
  } catch (error: any) {
    console.error("Erro ao buscar CEP:", error?.message || error);
  }
};

export const BuscaCNPJTanis = async (cnpj: string) => {
  if (!isAuthenticatedDBWEB()) {
    await GetTokenDBWEB();
  }
  try {
    const response = await apiDBWEB.get(`${urlBuscaCnpjTanis}${cnpj}`);
    return response;
  } catch (error) {
    console.error("Erro ao buscar CNPJ Tanis:", error);
    throw error;
  }
};

export const BuscaCNPJ = async (cnpj: string) => {
  if (!isAuthenticatedDBWEB()) {
    await GetTokenDBWEB();
  }

  try {
    const response = await apiDBWEB.get(`${urlBuscaCnpj}${cnpj}`);
    return response;
  } catch (error) {
    console.error("Erro ao buscar CNPJ:", error);
    throw error;
  }
};
