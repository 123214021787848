export default class LoginModel {
  login: string;
  password: string;
  cnpj: string;
  sistema: string;

  // Construtor para inicializar as propriedades
  constructor(
    login: string = "",
    password: string = "",
    cnpj: string = "",
    sistema: string = ""
  ) {
    this.login = login;
    this.password = password;
    this.cnpj = cnpj;
    this.sistema = sistema;
  }

  // Método toJson que retorna as propriedades como um objeto JSON
  toJson() {
    return {
      login: this.login,
      password: this.password,
      cnpj: this.cnpj,
      sistema: this.sistema,
    };
  }
}
