import dayjs from "dayjs";

export default class SingupModel {
  nome: string;
  cpf: string;
  dataNascimento: string;
  email: string;
  senha: string;

  constructor(
    nome: string = "",
    cpf: string = "",
    dataNascimento: string = "",
    email: string = "",
    senha: string = ""
  ) {
    this.nome = nome;
    this.cpf = cpf;
    this.dataNascimento = dataNascimento;
    this.email = email;
    this.senha = senha;
  }
}
