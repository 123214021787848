import React, { useState } from "react";
import { Container, TextField, Button, Grid } from "@mui/material";
import { BuscaCNPJ, getBuscaCep } from "../../services/Utils.Service";
import {
  getCadastro,
  postCadastro,
  putAssociar,
  postDocumento,
  postEndereco
} from "../../services/Login.Service";
import CadCadastroModel from "../../models/cadcadastro.model";
import caddocumento from "../../models/caddocumento.model";
import CadastroEnderecoModel from "../../models/cadendereco.model";
import { useNavigate } from "react-router-dom";
import "./TipodeAcesso.scss";

const TipodeAcesso = () => {
  const [cnpj, setcnpj] = useState<string>("");
  const [label, setlabel] = useState<string>("");
  const [cadastro, setcadastro] = useState<CadCadastroModel>(
    new CadCadastroModel()
  );
  const [caddoc, setcaddoc] = useState<caddocumento>(new caddocumento());
  const [cadend, setcadend] = useState<CadastroEnderecoModel>(new CadastroEnderecoModel());
  const navigate = useNavigate();

  const formatarCNPJ = (cnpj: string) => {
    const cnpjLimpo = cnpj.replace(/\D/g, "");
    return cnpjLimpo.length === 14 ? cnpjLimpo : "";
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setcnpj(e.target.value);
  };

  const Buscar = () => {
    getCadastro(formatarCNPJ(cnpj))
      .then(async (result) => {
        if (result.status === 200) {
          const idCadastro = result.data.info.dados[0].id_cadastro;                  
         await putAssociar(idCadastro);         
         navigate("/home");
        } else {
          BuscaCNPJ(cnpj).then(async (result) => {
            if (result.status === 200) {
              setlabel(result.data.info.dados.nome);
              const dadosCNPJ = result.data.info.dados;
              const novoCadastro = {
                ...cadastro,
                nome: dadosCNPJ.nome,
                tipo_pessoa: "Juridica",
                fantasia: dadosCNPJ.fantasia,
              };
              setcadastro(novoCadastro);

              const res = await postCadastro(novoCadastro);

              if (res.status === 200) {
                const idCadastro = res.data.info.dados.id_cadastro;                
                const novoDoc = {
                  ...caddoc,
                  id_cadastro: idCadastro,
                  tipo_documento: {
                    ...caddoc.tipo_documento,
                    cod_tipo_documento: 2,
                    ds_tipo_documento: "CNPJ",
                    tipo_pessoa: "Juridica",
                  },
                  ds_documento: formatarCNPJ(cnpj),
                };
                setcaddoc(novoDoc);                
                await postDocumento(novoDoc);
                const novoend = {
                  ...cadend,
                  id_cadastro: idCadastro,                  
                  cod_logradouro: await getBuscaCep(res.data.info.dados.cep),
                  numero_endereco:res.data.info.dados.numero,
                  numero_complemento:res.data.info.dados.complemento
                };
                setcadend(novoend); 
                await postEndereco(novoend);
                await putAssociar(idCadastro);
                setlabel("Cadastro Solicitado");
                navigate("/home");
              }
            }
          });
        }
      })
  };

  return (
    <Container>
      <div className="acesso-container">
        <div className="card-type">
          <h2 className="title">Se conecte a uma empresa</h2>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="cnpj"
                  label="CNPJ"
                  value={cnpj}
                  onChange={handleChange}
                  fullWidth
                  className="input-field"
                  placeholder="00.000.000/0000-00"
                />
              </Grid>
            </Grid>
            <h3>{label}</h3>
          </div>
          <div className="actions">
            <Button
              variant="contained"
              color="primary"
              onClick={Buscar}
              sx={{ mt: 2, mr: 1 }}
            >
              Buscar
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TipodeAcesso;
