export default class CadCadastroModel {
  id_cadastro: string | null;
  status: string;
  tipo_pessoa: string;
  nome: string;
  fantasia: string;
  estrangeiro: string;
  nascimento: string | null;

  constructor(
    id_cadastro: string | null = null,
    status: string = "Ativo",
    tipo_pessoa: string = "Fisica",
    nome: string = "",
    fantasia: string = "",
    estrangeiro: string = "N",
    nascimento: string | null = null
  ) {
    this.id_cadastro = id_cadastro;
    this.status = status;
    this.tipo_pessoa = tipo_pessoa;
    this.nome = nome;
    this.fantasia = fantasia;
    this.estrangeiro = estrangeiro;
    this.nascimento = nascimento;
  }
}
