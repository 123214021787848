import React, { useEffect, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { isAuthenticated } from '../services/Auth/Auth.Service';

interface PrivateRouteProps {
  element: ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated()) {
      navigate('/login');
    }
  }, [navigate]);

  return isAuthenticated() ? <>{element}</> : null;
};

export default PrivateRoute;
