import React, { useEffect, useState, ChangeEvent } from "react";
import { Button, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {ReenvioToken , ConfirmarEmail} from "../../services/Login.Service"
import "./ConfirmEmail.scss";

const ConfirmEmail: React.FC = () => {
  const [status, setStatus] = useState<0 | 1 | 2 | 3>(0); // 0 - Verificando, 1 - Token Válido, 2 - Token Inválido, 3 - Solicitação Enviada
  const [email, setEmail] = useState<string>("");

 

  const navigate = useNavigate();
  const { token } = useParams<{ token: string }>();

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const reenviaToken = async () => {
    const result = await ReenvioToken(email)
    if (result.success) {
      setStatus(3); // Solicitação enviada
    } else {    
     console.error("Erro ao reenviar token, tente novamente mais tarde!");
    }
  };

  const confirmaEmail = async () => {
    const result = await ConfirmarEmail(token)    
    if (result.success) {
      setStatus(1); // Token válido      
    } else { 
      setStatus(2); // Token inválido
      console.error("Erro ao confirmar email, tentenovamente mais tarde");        
    }
  };

  useEffect(() => {
    confirmaEmail();
  },[])

  return (
    <div className="contenier-confirmmail">
      <div className="card">
        {status === 0 && (
          <div>
            <h2>Verificando Token ...</h2>
          </div>
        )}
        {status === 1 && (
          <div>
            <h2>E-Mail Confirmado</h2>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          </div>
        )}
        {status === 2 && (
          <div>
            <h2>Token Expirado!</h2>
            <div className="input-container">
              <TextField
                id="email"
                label="Digite seu E-mail"
                variant="outlined"
                value={email}
                onChange={handleEmailChange}
                className="input-field"
              />
            </div>
            <Button variant="contained" color="primary" onClick={reenviaToken}>
              Solicitar Novo Token
            </Button>
          </div>
        )}
        {status === 3 && (
          <div>
            <h2>Solicitação Enviada</h2>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ConfirmEmail;
