import axios from "axios";
import environment from "../../environments/environments";
import * as CryptoJS from "crypto-js";

const urlTokenDBWEB = environment.urlTokenDBWEB;
const urlLogin = environment.urlLogin;

interface APIResponse<T> {
  info?: {
    dados?: T;
  };
}

interface LoginResponse {
  token: string;
  usuario: { nome: string, chave: string, cpf: string };
}

interface TokenResponse {
  token: string;
}

const getMainDomain = () => {
  const hostname = window.location.hostname;
  const parts = hostname.split(".");
  return parts.length > 2 ? parts.slice(-3).join(".") : parts.slice(-2).join(".");
};


export const setCookie = (name: string, value: string, hours: number) => {
  const expires = new Date(Date.now() + hours * 60 * 60 * 1000).toUTCString();
  const domain = getMainDomain();
  document.cookie = `${name}=${value}; expires=${expires}; path=/; domain=${domain}; Secure; SameSite=Strict`;
};

export const isAuthenticated = () => {
  return document.cookie
    .split("; ")
    .some(row => row.startsWith("authToken="));
};

export const isAuthenticatedDBWEB = () => {
  return document.cookie
    .split("; ")
    .some(row => row.startsWith("TokenDBWEB="));
};

export const login = async (body: Record<string, any>) => {
  try {
    const response = await axios.post<APIResponse<LoginResponse>>(`${urlLogin}`, body);

    const dados = response.data.info?.dados;
    if (dados?.token && dados?.usuario?.nome) {
      setCookie("idCad", dados.usuario.chave, 12);
      setCookie("authToken", dados.token, 12);
      document.cookie = `username=${dados.usuario.nome}; path=/`;
      return { success: true, doc: response.data.info?.dados?.usuario.cpf};
    } else {
      throw new Error("Estrutura de resposta inesperada.");
    }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.info?.dados?.descricao || error.message || "Erro desconhecido";
    document.cookie = `authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; Secure; SameSite=Strict;`;
    return { success: false, error: errorMessage };
  }
};

export const logout = (): void => {
  const domain = window.location.hostname;
  document.cookie.split(';').forEach((cookie) => {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie.trim();

    // Remove o cookie para o domínio e seus subdomínios
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=${domain}`;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=.${domain}`;
});
  window.location.reload();
};

export const GetTokenDBWEB = async () => {
  try {
    const hashPassword = CryptoJS.MD5("TANIS24").toString();

    const body = {
      cnpj: "01114682000126",
      sistema: "Mercantis",
      login: "Master",
      password: hashPassword,
    };

    const response = await axios.post<APIResponse<TokenResponse>>(`${urlTokenDBWEB}`, body);

    const dados = response.data.info?.dados;
    if (dados?.token) {
      setCookie("TokenDBWEB", dados.token, 12);
      return { success: true };
    } else {
      throw new Error("Estrutura de resposta inesperada.");
    }
  } catch (error: any) {
    const errorMessage =
      error.response?.data?.info?.dados?.descricao || error.message || "Erro desconhecido";
    return { success: false, error: errorMessage };
  }
};
